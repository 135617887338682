import { Injectable, Injector } from '@angular/core';
import { LogService } from '@pos-common/services/system/logger';
import { ILogger } from '@spryrocks/logger';
import { Buffer } from 'buffer';
import NexoCrypto from '../security/nexo-crypto.class';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { AdyenUtils } from '../adyen-utils';
import { Actions, ofType } from '@ngrx/effects';
import { AddSoftPosTerminalAdyenSuccess, ErrorActionTerminal } from '@pos-stores/settings';
import { catchError, filter, map, timeout } from 'rxjs/operators';
import { IActiveTerminal } from '@pos-common/interfaces';
import { softPOsTerminalHelper } from '@pos-common/services/system/adyen/services/helpers/soft-pos-terminal.helper';
import { Observable, Subject, take, throwError } from 'rxjs';
import { AdyenPaymentApi, AdyenTerminalApi } from '@pos-common/services/system/adyen/services/api';
import { MakePaymentResponse, PaymentResponse } from '@pos-common/services/system/adyen/adyen-types';
import {
  AdyenPaymentError,
  AdyenTerminalInfo,
  AdyenTerminalSettingsInfo,
  SaleToPoiSecuredMessage,
  SecurityKey,
} from '@pos-common/services/system/adyen/classes';
import { AppLauncher, CanOpenURLResult, OpenURLResult } from '@pos-common/classes';
import { StorageKeys } from '@pos-common/constants';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';
import { PaymentService } from '@pos-common/services/system/payment.service';
import { PosSettingsService } from '@pos-common/services/system/pos-settings.service';
import { AdyenSoftPosTargetAction, ErrorCondition } from '@pos-common/services/system/adyen/enums';
import { AdyenPaymentErrors } from '@pos-common/services/system/adyen/costants';

@Injectable()
export class AdyenSoftPosService {
  private readonly settings: Record<string, string> = SERVER_CONFIG.ADYEN_PAYMENT_APPLICATION_SETTINGS;
  private readonly logger: ILogger = this.logService.createLogger('AdyenSoftPosService');
  private paymentResultSubject$: Subject<MakePaymentResponse>;
  private timeForWaitApplicationResponse: number = 90000;

  constructor(
    private inject: Injector,
    private logService: LogService,
    private adyenUtils: AdyenUtils,
    private actions$: Actions,
    private adyenApiService: AdyenPaymentApi,
    private terminalApi: AdyenTerminalApi,
    private localStorage: LocalStorage,
    private posSettingsService: PosSettingsService
  ) {}

  get softPOSAddedResultAction(): Observable<{ terminal: IActiveTerminal } | { error: unknown }> {
    return this.actions$.pipe(
      ofType(AddSoftPosTerminalAdyenSuccess, ErrorActionTerminal),
      map((action): { terminal: IActiveTerminal } | { error: unknown } => {
        if (action?.['terminal']) {
          return {
            terminal: softPOsTerminalHelper(action?.['terminal']),
          };
        }
        if (action?.['error']) {
          this.logger.error(action?.['error'], 'softPOSAddedResultAction');
          return { error: action['error'] };
        }
        this.logger.error(action, 'softPOSAddedResultAction unknown error');
        return { error: null };
      })
    );
  }

  isSoftPosEvent(url: URL): boolean {
    return url?.href?.includes('softpos') || url?.hash?.includes('softpos');
  }

  async isAppInstalled(): Promise<boolean> {
    if (!this.settings?.packageName) {
      this.logger.error('Settings not include Adyen App: packageName');
      return false;
    }

    const canOpen: CanOpenURLResult = await AppLauncher.canOpenUrl({ url: this.settings.packageName });
    return canOpen.value;
  }

  async softPosBoardedStatus(): Promise<boolean> {
    if (!this.settings?.targetUrl || !this.settings?.backUrl) {
      this.logger.error("Settings not include urls for App's: softPosBoardedStatus");
      return false;
    }

    const targetAppUrlScheme: string = `${this.settings?.targetUrl}://boarded`;
    const returnUrl: string = `${this.settings?.backUrl}://localhost:8080/settings?softpos=${AdyenSoftPosTargetAction.Boarded}`;

    const fullDeepLink: string = `${targetAppUrlScheme}?returnUrl=${returnUrl}`;
    const isAppInstalled: boolean = await this.isAppInstalled();

    if (!isAppInstalled) {
      this.logger.error({ error: 'Payment App not install' }, 'softPosBoardedStatus');
      return isAppInstalled;
    }

    const openedResult: OpenURLResult = await AppLauncher.openUrl({ url: fullDeepLink });
    return openedResult.completed;
  }

  async softPosBoardingProcess(token: string): Promise<boolean> {
    if (!this.settings?.targetUrl || !this.settings?.backUrl) {
      this.logger.error("Settings not include urls for App's: softPosBoardingProcess");
      return false;
    }

    const targetAppUrlScheme: string = `${this.settings?.targetUrl}://board`;
    const returnUrl: string = `${this.settings?.backUrl}://localhost:8080/settings?softpos=${AdyenSoftPosTargetAction.Boarding}`;

    const token64Encoded: string = Buffer.from(token).toString('base64');
    const fullDeepLink: string = `${targetAppUrlScheme}?boardingToken=${token64Encoded}&returnUrl=${returnUrl}`;

    const openedResult: OpenURLResult = await AppLauncher.openUrl({ url: fullDeepLink });
    return openedResult.completed;
  }

  async createPaymentToSoftPos(request): Promise<MakePaymentResponse> {
    this.logger.info('createPaymentToSoftPos', { request });
    this.paymentResultSubject$ = new Subject<MakePaymentResponse>();

    const targetAppUrlScheme: string = `${this.settings?.targetUrl}://nexo`;
    const returnUrl: string = `${this.settings?.backUrl}://localhost:8080/collection/checkout?softpos=${AdyenSoftPosTargetAction.Payment}`;

    this.adyenUtils.checkNetworkConnection();

    const saleToPOISecuredMessageBase64URL: string = await this.encryptedRequestToString(request);
    const fullDeepLink: string = targetAppUrlScheme + '?request=' + saleToPOISecuredMessageBase64URL + '&returnUrl=' + returnUrl;

    await AppLauncher.openUrl({ url: fullDeepLink });

    return await this.paymentResultSubject$
      .pipe(
        filter((v: MakePaymentResponse): boolean => !!v),
        take(1),
        timeout(this.timeForWaitApplicationResponse),
        catchError((error: Error) => {
          this.adyenUtils.checkNetworkConnection();
          if (error.name === 'TimeoutError') {
            return throwError((): AdyenPaymentError => new AdyenPaymentError(
              AdyenPaymentErrors.timeoutWaitingResponse,
              ErrorCondition.SoftPosError,
              ErrorCondition.SoftPosError,
              AdyenPaymentErrors.timeoutWaitingResponse,
              true
            ));
          }
          return throwError((): Error => error);
        })
      )
      .toPromise();
  }

  async openPlayStore() {
    const isAvailable = await AppLauncher.canOpenUrl({ url: `market://details?id=${this.settings.packageName}` });

    if (isAvailable.value) {
      await AppLauncher.openUrl({ url: `market://details?id=${this.settings.packageName}` });
    } else {
      await AppLauncher.openUrl({ url: `https://play.google.com/store/apps/details?id=${this.settings.packageName}` });
    }
  }

  public async setResultPaymentFromSoftPos(response: string, hasError: boolean): Promise<void> {
    this.logger.info('setResultPaymentFromSoftPos raw', { response, hasError });
    this.adyenUtils.checkNetworkConnection();
    const rawPaymentResponse: PaymentResponse = !hasError ? await this.decryptedRequestToString(response) : { errorCondition: ErrorCondition.SoftPosError, message: response };
    this.adyenApiService
      .softPosResult(rawPaymentResponse, hasError)
      .then((response: MakePaymentResponse) => {
        this.logger.info('softPosResult', { response });
        this.paymentResultSubject$.next(response);
      })
      .catch((error: AdyenPaymentError) => {
        this.logger.error(error, 'softPosResult');
        this.paymentResultSubject$.error(error);
      });
  }

  public gettingTargetActionSoftPos(url: URL): string {
    this.logger.info('gettingTargetActionSoftPos', { url });
    const value: string = (url.href || '') + '&' + (url.hash || '');

    if (!value) {
      this.logger.error( { url }, 'gettingTargetActionSoftPos');
      return null;
    }

    const match: RegExpMatchArray = value.match(/[\?&]softpos=([^&]+)/);

    if (match) {
      return match[1];
    } else {
      this.logger.error( { url }, 'gettingTargetActionSoftPos match softpos');
      return null;
    }
  }

  public async saveActiveTerminal(terminal: AdyenTerminalInfo): Promise<void> {
    const fullTerminal: IActiveTerminal = softPOsTerminalHelper(terminal);
    this.localStorage.setObject(StorageKeys.activeTerminal, fullTerminal);
    const paymentService: PaymentService = this.inject.get(PaymentService);
    const paymentMethod: PaymentMethod = await paymentService.createNewPaymentMethod(fullTerminal.title);
    this.posSettingsService.setSelectedPaymentMethod(paymentMethod);
  }

  private async encryptedRequestToString(request): Promise<string> {
    const requestString: string = this.adyenUtils.stringify(request);

    const { SaleToPOIRequest } = request;
    const { MessageHeader } = SaleToPOIRequest;

    const securityKey: SecurityKey | null = await this.getSecuretyKey();

    if (!securityKey) {
      this.logger.error('encryptedRequestToString securityKey not found');
      this.handleError('PaymashPay encryption key not found.');
    }

    const saleToPOIEncryptRequest: SaleToPoiSecuredMessage = await NexoCrypto.encryptAsync(MessageHeader, requestString, securityKey);

    const saleToPOIRequest = {
      SaleToPOIRequest: {
        ...saleToPOIEncryptRequest,
      },
    };
    const message: string = this.adyenUtils.stringify(saleToPOIRequest);
    return AdyenUtils.encodeBase64URL(message);
  }

  private async getSecuretyKey(): Promise<SecurityKey> {
    const merchantId = this.localStorage.get(StorageKeys.paymashPayMerchantId);
    if (!merchantId) this.handleError('Please provide PaymashPay MerchantID in the settings');

    const info: AdyenTerminalSettingsInfo = await this.terminalApi.getTerminalSettings(merchantId);

    if (info?.nexo?.encryptionKey && info?.nexo?.encryptionKey.identifier && info?.nexo?.encryptionKey.passphrase) {
      const key = info.nexo.encryptionKey;
      return {
        AdyenCryptoVersion: 1,
        KeyIdentifier: key.identifier,
        KeyVersion: key.version,
        Passphrase: key.passphrase,
      } as SecurityKey;
    } else {
      this.logger.error({encryptKey: info?.nexo?.encryptionKey }, 'getSecuretyKey: SecurityKey not found');
      return null;
    }
  }

  private async decryptedRequestToString(response: string): Promise<unknown> {
    if (!response) {
      this.handleError('PaymashPay response is empty');
    }

    const saleToPOIEncryptRequest: SaleToPoiSecuredMessage = this.adyenUtils.parse<SaleToPoiSecuredMessage>(
      AdyenUtils.decodeBase64URL(response)
    );
    const nexo: NexoCrypto = new NexoCrypto();
    const securityKey: SecurityKey | null = await this.getSecuretyKey();

    if (!securityKey) {
      this.logger.error('decryptedRequestToString: SecurityKey not found');
      this.handleError('PaymashPay encryption key not found.');
    }

    const resp: string = await nexo.decryptAsync(saleToPOIEncryptRequest?.['SaleToPOIResponse'], securityKey);

    return this.adyenUtils.parse(resp);
  }

  private handleError(message: string): AdyenPaymentError {
    return new AdyenPaymentError(message);
  }
}
