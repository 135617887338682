export const SelfOrderShortOrderNumberUtil = (invoiceId: string): string => {
  if (invoiceId) {

    const regexGetValueAfterDash = /-(.*)/g;

    return invoiceId ? regexGetValueAfterDash.exec(invoiceId as string)?.[1] : ''

  } else {
    return ''
  }
}
